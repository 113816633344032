import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Container, Typography } from '@material-ui/core'
import { graphql } from 'gatsby'
import ContentHeading from '../components/typography/ContentHeading'
import Layout from '../components/Layout'
import ComponentsList from '../components/components-list/List'
import HeroImage from '../components/HeroImage'
import RelatedContent from '../components/RelatedContent'
import AboutBlock from '../components/AboutBlock'
import NewsletterBlock from '../components/NewsletterBlock'
import EpisodeListCarousel from '../components/EpisodeListCarousel'
import HeroVideo from '../components/media/mixed-media-video-component/Video'
import GlobalContext from '../context/GlobalContext'

export const MixedMediaTemplate = ({
  content: {
    frontmatter,
    fields,
  },
  publisherData,
  url,
}) => {
  const {
    heroImg, heroImageCaption, components,
  } = frontmatter

  useEffect(
    () => {
      const componentsList = document.querySelector('.components-list')

      if (componentsList) {
        const componentListLinks = componentsList.querySelectorAll('a')

        componentListLinks.forEach((node) => {
          const el = node

          if (el.hostname !== window.location.hostname) {
            el.target = '_blank'
          }
        })
      }
    },
    [],
  )

  return (
    <>
      {frontmatter.mediaUrl ? (
        <GlobalContext.Consumer>
          {({ addPlayerApiReference, onCurrentMediaChange }) => (
            <HeroVideo
              data={{
                videoComponent: { videoId: frontmatter.mediaUrl },
              }}
              onMediaChange={onCurrentMediaChange}
              setPlyrRef={
                  (newPlyrRef) => addPlayerApiReference && addPlayerApiReference(newPlyrRef)
                }
            />
          )}
        </GlobalContext.Consumer>
      ) : <HeroImage image={heroImg} caption={heroImageCaption} />}
      {/* {
        data: {
          videoComponent: { videoId },
        },
        onMediaChange,
        setPlyrRef,
      } */}
      <Container>
        <ContentHeading
          data={{ seriesType: 'read', ...frontmatter, episodeNumber: fields.autoEpisodeNumber }}
          publisherData={publisherData}
          url={url}
        />
      </Container>
      <ComponentsList components={components} />
    </>
  )
}

MixedMediaTemplate.propTypes = {
  content: PropTypes.shape({
    frontmatter: {
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      heroImg: PropTypes.shape({}).isRequired,
      heroImageCaption: PropTypes.string,
      mediaUrl: PropTypes.string,
      mediaTranscript: PropTypes.string,
      publisher: PropTypes.string.isRequired,
      tags: PropTypes.array.isRequired,
      shareicons: PropTypes.arrayOf(
        PropTypes.shape({
          icontype: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }),
      ),
      description: PropTypes.string.isRequired,
      mediaDescription: PropTypes.string,
      components: PropTypes.arrayOf(PropTypes.shape({})),
      metaTitle: PropTypes.string,
      metaDescription: PropTypes.string,
      ogImage: PropTypes.shape({}),
      hidePost: PropTypes.bool,
    },
    fields: PropTypes.shape({
      autoEpisodeNumber: PropTypes.number,
    }),
  }).isRequired,
  publisherData: PropTypes.shape({
    frontmatter: PropTypes.shape({
      label: PropTypes.string,
      image: PropTypes.string,
    }),
  }).isRequired,
  url: PropTypes.string.isRequired,
}

const MixedMedia = ({
  data: {
    pageContent,
    relatedContent,
    series,
    seriesEpisodes: { edges: seriesEpisodes },
    publisherData,
  },
}) => {
  const {
    id, title, description, metaTitle, metaDescription, ogImage, hidePost,
  } = pageContent.frontmatter
  const [activeEpisodes, setActiveEpisodes] = useState(false)
  const filteredRelatedContent = relatedContent.edges
    .filter((episode) => episode.node.frontmatter.id !== id
        && !seriesEpisodes.some((seriesEpisode) => seriesEpisode.node.id === episode.node.id))

  useEffect(() => {
    window.scrollTo(0, 0)
    window.dataLayer.push({ page: null })
    window.dataLayer.push({
      event: 'page_view',
      page: {
        '@type': 'WebArticle',
        article: {
          author: publisherData.frontmatter.label,
          length: pageContent.frontmatter.mediaDuration,
          category: pageContent.frontmatter.templateKey,
          name: pageContent.frontmatter.title,
          published: pageContent.frontmatter.date,
        },
        breadcrumb: series ? `Home > ${series.frontmatter.title} > ${pageContent.frontmatter.title}` : 'Home',
        id: pageContent.id,
        name: pageContent.frontmatter.title,
        page_category: 'Post',
        page_category2: series?.frontmatter.title,
        page_category3: pageContent.frontmatter.title,
      },
    })
  }, [])
  return (
    <>
      <Layout metaData={{
        title,
        description,
        metaTitle: metaTitle || title,
        metaDescription: metaDescription || description,
        image: ogImage ? ogImage.childImageSharp.fluid.src
          : pageContent.frontmatter?.heroImg?.childImageSharp?.fluid?.src,
        slug: pageContent.fields.slug,
        type: 'article',
      }}
      >
        <MixedMediaTemplate
          content={pageContent}
          publisherData={publisherData}
          url={pageContent.fields.slug}
        />
        {seriesEpisodes.length > 1 ? (
          <Box my={{ xs: 6, lg: 11.25 }}>
            <Container>
              <Box
                textAlign="center"
                mb={6}
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h4">
                  Other Posts In This Series
                </Typography>
              </Box>
              <EpisodeListCarousel
                currentEpisodeId={id}
                content={seriesEpisodes.filter((item) => id !== item.node.frontmatter.id)}
                setActive={setActiveEpisodes}
                active={activeEpisodes}
                currentEpisodeNumber={pageContent.fields.autoEpisodeNumber}
                sorted
              />
            </Container>
          </Box>
        ) : null}
        {
          hidePost ? null : <RelatedContent content={filteredRelatedContent} />
        }
        <AboutBlock />
        <NewsletterBlock />
      </Layout>
    </>
  )
}

MixedMedia.propTypes = {
  data: PropTypes.shape({
    pageContent: PropTypes.shape({
      id: PropTypes.string,
      fields: PropTypes.shape({
        slug: PropTypes.string,
        autoEpisodeNumber: PropTypes.number,
      }),
      frontmatter: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        date: PropTypes.string,
        mediaDuration: PropTypes.number,
        mediaDescription: PropTypes.string,
        heroImg: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              src: PropTypes.string,
            }),
          }),
        }),
        templateKey: PropTypes.string,
        metaTitle: PropTypes.string,
        metaDescription: PropTypes.string,
        ogImage: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              src: PropTypes.string,
            }),
          }),
        }),
        hidePost: PropTypes.bool,
      }),
    }).isRequired,
    series: PropTypes.shape({
      frontmatter: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        mediaDescription: PropTypes.string,
        heroImg: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              src: PropTypes.string,
            }),
          }),
        }),
        metaTitle: PropTypes.string,
        metaDescription: PropTypes.string,
        ogImage: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              src: PropTypes.string,
            }),
          }),
        }),
        hidePost: PropTypes.bool,
      }),
    }).isRequired,
    seriesEpisodes: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    relatedContent: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    publisherData: PropTypes.shape({
      frontmatter: PropTypes.shape({
        label: PropTypes.string,
        image: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    episodeIds: PropTypes.array,
  }).isRequired,
}

export const mixedMediaQuery = graphql`
  query MixedMediaById(
    $id: String!
    $seriesId: String!
    $relatedContentIds: [String]
    $episodeIds: [String]
    $publisherId: String
  ) {
    pageContent: markdownRemark(frontmatter: { id: { eq: $id } }) {
      ...PostOrSeriesFields
      frontmatter {
        ...Markdown
        ...Title
        ...Text
        ...Quote
        ...AudioText
        ...ImageText
        ...ImageQuote
        ...Bullets
        ...BulletsImagesTypeOne
        ...BulletsImagesTypeTwo
        ...FullWidthImage
        ...Polls
        ...Video
        metaTitle
        metaDescription
        ogImage{
          childImageSharp {
            fluid{
              src
            }
          }
        }
        hidePost
      }
    }
    series: markdownRemark(frontmatter: { id: { eq: $seriesId } }) {
      ...PostOrSeriesFields
    }
    seriesEpisodes: allMarkdownRemark(
      filter: { frontmatter: { id: { in: $episodeIds } hidePost : { ne : true} } }
    ) {
      edges {
        node {
          ...PostOrSeriesFields
          ...MediaThumbnail
        }
      }
    }
    relatedContent: allMarkdownRemark(filter: { frontmatter:
      {
        id: { in: $relatedContentIds }
        hidePost : { ne : true}
    } }) {
      edges{
        node {
          ...PostOrSeriesFields
          ...MediaThumbnail
        }
      }
    }
    publisherData: markdownRemark(
      frontmatter : {
        id : { eq: $publisherId}
      }
  ){
    frontmatter{
      label
      image
      text1
      text2
    }
  }

  }
`

export default MixedMedia
